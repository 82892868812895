import { UseCMSPartner } from "@with-nx/hooks-n-helpers";
import { Rule } from "@with-nx/simple-ui/atoms";
import { PartnerStaticData } from "@with-nx/static";
import Formatter from "libs/hooks-n-helpers/src/Formatter";
import { Box } from "simple-effing-primitive-layout";
import Image from "next/image";

export const HomePartners = ({
  mobile,
  tablet,
}: {
  mobile: boolean;
  tablet: boolean;
}) => {
  const partners = PartnerStaticData?.getAllPartners();

  return (
    <div className="container">
      <Rule parse="d:block ta:center !hxl c:var(--gold-100) mb:15">
        Our Partners
      </Rule>
      <Rule parse="d:block ta:center !_bxl">
        Through our partnerships with highly respected names in the industry, we
        remain committed to ensuring {mobile || tablet ? null : <br />} that
        everyone can experience the magic of Broadway.
      </Rule>
      <Box
        style={{
          rowGap: mobile ? "47px" : "",
          display: "grid",
          gridTemplateColumns: `repeat(${
            tablet ? 3 : mobile ? 1 : partners.length
          }, 1fr)`,
        }}
      >
        {partners.map((partner: UseCMSPartner) => {
          return (
            <Box
              key={partner.slug}
              parse="d:flex a:center j:center"
              native={{
                href: `/partners/${partner.slug}`,
                rel: "noreferrer",
              }}
              element="a"
            >
              <Box parse="p:relative mt:45 w:167 mh:113">
                <Image
                  loading="lazy"
                  src={Formatter.compressed(partner.image, 256, true)}
                  alt={partner.name || "Broadway Media"}
                  layout="fill"
                  objectFit="contain"
                  objectPosition="center"
                />
              </Box>
            </Box>
          );
        })}
      </Box>
    </div>
  );
};
