import {
  Formatter,
  useCMSGatedContentPromotion,
  useCMSHomepage,
} from "@with-nx/hooks-n-helpers";
import { useServerRegion } from "@with-nx/region";
import HomeSlidersTemplate from "./HomeSlidersTemplate";
import {
  GridBlog,
  GridStepScroller,
  ReusableSlantedContainer,
  AutoScroller,
} from "@with-nx/simple-ui/organisms";
import {
  DesignedButton,
  ReusableCustomerServiceContainer,
  ReusableSection,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { HomePartners, HomeSolutions } from "@with-nx/simple-ui/templates";
import { Box } from "simple-effing-primitive-layout";
import Image from "next/image";

export const CMSContentContainer = ({
  mobile,
  tablet,
}: {
  mobile: boolean;
  tablet: boolean;
}) => {
  const region = useServerRegion();
  const homepage = useCMSHomepage({
    region: region?.id,
  });

  const blogs =
    homepage?.data?.blogs.map((blog) => {
      return (
        <GridBlog
          key={blog._id}
          date={blog.date}
          description={blog.description}
          image={Formatter.compressed(blog.coverImage, 256, true)}
          title={blog.title}
          url={`/blog/${blog.slug}`}
          transparent={false}
          properties={{
            height: "100%",
          }}
        />
      );
    }) || [];

  const gatedContentPromotions = useCMSGatedContentPromotion({}, !!region?.id);
  const mobileOrTablet = mobile || tablet;

  return (
    <>
      <ReusableSlantedContainer>
        <HomePartners mobile={mobile} tablet={tablet} />
        <HomeSolutions mobile={mobile} tablet={tablet} />
        <ReusableCustomerServiceContainer
          showMobile
          title={
            <Rule parse={`!_h2 ${mobile ? "ta:left" : ""}`}>
              Need assistance?
              <br />
              We are waiting in the wings!
            </Rule>
          }
          content={
            <Box>
              <Box parse={`d:flex ${mobile ? "fd:column ta:left" : ""} mb:10`}>
                <Rule parse="!bxl c:var(--grey-40) mr:20">Phone</Rule>
                <Rule parse="!bxl">1-800-277-0343</Rule>
              </Box>

              <Box parse={`d:flex ${mobile ? "fd:column ta:left" : ""} mb:20`}>
                <Rule parse="c:var(--grey-40) mr:20">Hours</Rule>

                <Rule>
                  Monday - Friday 8:00am - 11:00pm ET
                  <br />
                  Saturday and Sunday 12:00pm - 11:00pm ET
                </Rule>
              </Box>
            </Box>
          }
          buttonLabel="Schedule a Meeting"
          buttonTheme="primary"
          actions={
            <Box parse="ml:10">
              <DesignedButton
                label="Join a Workshop"
                theme="tetriary"
                href="/workshops"
              />
            </Box>
          }
          xl={8}
        />
      </ReusableSlantedContainer>

      <HomeSlidersTemplate homepage={homepage?.data} />
      <ReusableSlantedContainer>
        {!!gatedContentPromotions?.promotions?.length && (
          <div className="container">
            <Rule
              parse={`d:block ta:center !_${
                mobile ? "h2" : "h1"
              } c:var(--gold-100) mt:100 mb:30`}
            >
              Broadway Media Community Exclusives
            </Rule>

            <ReusableSection>
              <AutoScroller duration={30} paddings={5} top={0} manual={mobile}>
                {gatedContentPromotions?.promotions.map((promotion, index) => {
                  const width = mobileOrTablet ? 333 : 1200;
                  const height = mobileOrTablet ? 249 : 677;

                  return (
                    <Box
                      parse={`p:relative w:${width} h:${height} c:var(--blue-95) br:20 d:flex a:flex-end pa:${
                        mobileOrTablet ? "20" : "73"
                      } oa:hidden`}
                      key={`promotion-${index}`}
                    >
                      {promotion?.featureImage && (
                        <Box
                          parse="z:1 i:1 p:absolute w:100% h:100% t:0 l:0 z:1"
                          id="-reusable-simple-hero-section"
                        >
                          <Image
                            src={Formatter.image(promotion?.featureImage)}
                            layout="fill"
                            objectFit="cover"
                            loading="lazy"
                          />
                        </Box>
                      )}

                      <Box parse="p:relative z:2">
                        <Rule
                          parse={`!_${
                            mobileOrTablet ? "h3" : "h1"
                          } d:block mb:${mobileOrTablet ? "5" : "15"}`}
                        >
                          {promotion.title}
                        </Rule>
                        <Rule
                          parse={`!_${
                            mobileOrTablet ? "bm" : "h3"
                          } d:block mb:${mobileOrTablet ? "5" : "15"}`}
                          weight={500}
                        >
                          {promotion.description}
                        </Rule>
                        <DesignedButton
                          label={promotion.button}
                          theme="tetriary-white"
                        />
                      </Box>
                    </Box>
                  );
                })}
              </AutoScroller>
            </ReusableSection>
          </div>
        )}

        <div className="container">
          <Rule
            parse={`d:block ta:center !_${
              mobile ? "h2" : "h1"
            } c:var(--gold-100) mt:100`}
          >
            Read all about it
          </Rule>
          <Rule parse={`d:block ta:center !_${mobile ? "bxl" : "h3"} mb:30`}>
            Our latest news & community {mobile ? <br /> : ""}resources
          </Rule>

          <GridStepScroller
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            inside
            items={blogs}
          />
        </div>
      </ReusableSlantedContainer>
    </>
  );
};
