import { UseCMSHomepage } from "@with-nx/hooks-n-helpers";
import { ReusableSection, Rule, Spotlights } from "@with-nx/simple-ui/atoms";
import { BlueUnderline } from "@with-nx/simple-ui/molecules";
import { TestimonialGallery } from "@with-nx/simple-ui/organisms";
import { Box } from "simple-effing-primitive-layout";

const Element = ({
  banner = false,
  titleTag = "h5",
  homepage,
}: {
  banner?: boolean;
  homepage?: UseCMSHomepage;
  titleTag?: string;
}) => {
  return (
    <ReusableSection
      bottom="32vw"
      override
      properties={{
        style: {
          backgroundColor: "var(--background)",
          backgroundImage: "url(/assets/section/stage-people.webp)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          backgroundBlendMode: "luminosity",
        },
      }}
    >
      <div className="container mx-auto max-w-7xl">
        <Rule parse="!dt d:block mb:24 c:?font1 ta:center" tag={titleTag}>
          Trusted by our Theatre Community
        </Rule>
      </div>

      {homepage?.testimonials?.length ? (
        <TestimonialGallery
          testimonials={homepage?.testimonials || []}
          per={3}
        />
      ) : undefined}

      {banner && (
        <Box
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            width: "100%",
            bottom: "120px",
          }}
        >
          <Box parse="d:flex a:center j:center fd:column pt:48 p:relative">
            <Spotlights width="900" height="450" top={-50} />

            <Rule parse="d:flex fd:column a:center" tag="h2">
              <Box parse="d:flex">
                <Rule parse="!dl mb:0" tag="span">
                  Shining a&nbsp;
                </Rule>
                <Box css="p:relative">
                  <Rule
                    parse="!dl d:inline-block p:relative mb:0"
                    display="block"
                    css="--blue-gradient-text"
                    bottom={0}
                    style={{ marginBottom: 0 }}
                    tag="span"
                  >
                    spotlight
                    <BlueUnderline height="auto" top="60%" />
                  </Rule>
                </Box>
              </Box>

              <Rule parse="!dl mb:0" tag="span">
                on the performing arts
                <br />
              </Rule>
            </Rule>
          </Box>
        </Box>
      )}
    </ReusableSection>
  );
};

export const HomeTestimonialsTemplate = ({
  banner = false,
  homepage,
  titleTag,
}: {
  lighthouse?: boolean;
  banner?: boolean;
  homepage?: UseCMSHomepage;
  titleTag?: string;
}) => {
  console.log("⚡️", "HomeTestimonialsTemplate");

  return <Element homepage={homepage} banner={banner} titleTag={titleTag} />;
};

export default HomeTestimonialsTemplate;
