import { DesignedButton, DesignedCard, Rule } from "@with-nx/simple-ui/atoms";
import {
  AnimatedInfiniteSlantedCards,
  TestimonialQuotes,
} from "@with-nx/simple-ui/organisms";
import { Box } from "simple-effing-primitive-layout";

interface HomeCatalogTestimonialsProps {
  mobile: boolean;
  tablet: boolean;
  hero: any;
}

export const HomeCatalogTestimonials = ({
  mobile,
  tablet,
  hero,
}: HomeCatalogTestimonialsProps) => {
  const testimonials = [
    {
      description:
        "We love the projections. Because of your company this is the best show I have ever directed in my 11 years at Spotswood High School in NJ.",
      location: "Spotswood Troupers Drama Club",
      show: "Mean Girls: High School Version",
    },
    {
      description:
        "Thank you! Your videos are beautiful and are creating magic at TIC. Our audiences are loving it and we consider it one of our best. We are thrilled with Broadway Media's projections.",
      location: "Theatre in the Country",
      show: "Anastasia",
    },
    {
      description:
        "Way better than creating bulky, costly sets. As Scuttle would say, they were fantabulous!",
      location: "Sing Studio Houston",
      show: "Disney's The Little Mermaid JR",
    },
    {
      description:
        "I absolutely loved using these projections-They created a world that I could not have managed by alternate means. Audiences were enthralled, the cast hugely enjoyed them.",
      show: "The Addams Family",
      location: "Strathfield Musical Society",
    },
    {
      description:
        "We were extremely pleased with the projections for our production of Beauty and the Beast. They added a magical element to the show and helped make the transitions seamless.",
      show: "Disney's Beauty and the Beast",
      location: "North Greenville University",
    },
    {
      description:
        "Everyone at Grande Prairie Live Theatre would like to thank Broadway Media for the excellent background graphics you provided us for our production of Shrek. These were colorful, detailed and very creative. They added to every scene.",
      show: "Shrek the Musical",
      location: "Grand Prairie Live Theater",
    },
    {
      description:
        "Using the scenic projections is like adding an additional character to the show. We got countless compliments on how professional the show looked with the projections. Thank you to the staff for helping us get everything ready for the show.",
      show: "9 to 5",
      location: "Rocori High School",
    },
    {
      description:
        "The digital backdrops were a great addition to our show … added a lot of action and believability in the scene, and really enhanced storytelling! Some of the backdrop videos even guided my blocking, and made the story more real … We had an overall really positive experience with Broadway Media!",
      show: "Disney's Finding Nemo JR",
      location: "Mesa Middle School",
    },
  ];
  const images = [...Array(72)].map((_, i) => `/assets/drops/${i + 1}.jpg`);

  return (
    <Box
      parse={`p:relative mh:${mobile ? "800" : "1200"} z:1 oa:hidden c:${
        hero.gradients.bottom
      }`}
    >
      <Box
        parse={`oa:visible p:absolute t:${mobile ? "150" : "0"} l:0 z:0`}
        style={{
          transform: "translate(-50%, 10vw) skew(3deg, 7deg)",
          opacity: "0.65",
        }}
        id="animated-cards"
      >
        <AnimatedInfiniteSlantedCards
          images={images}
          size={8}
          rows={mobile ? 4 : 5}
        />
      </Box>

      <Box
        parse={`p:absolute w:100% h:100% ${
          mobile ? "b:200" : "t:0"
        } t:0 l:0 z:0`}
        style={{
          background: `linear-gradient(0deg, ${
            hero.gradients.bottom
          } 12%, rgba(255, 255, 255,0.00)  ${mobile ? "180%" : "100%"})`,
        }}
      />

      <Box parse="i:3">
        <div className="container">
          <Rule
            parse={`!${
              mobile ? "_h2" : "hxl"
            } d:block ta:center mb:14 c:var(--gold-100)`}
            weight={700}
          >
            The Perfect Tools for {mobile ? <br /> : ""} Any Stage
          </Rule>
          <Rule parse="!_bxl d:block ta:center mb:30">
            Watch how Broadway Media can make your stage come alive.
          </Rule>

          <Box parse="d:flex j:center mb:30 w:100%">
            <DesignedCard
              size="16x9"
              embed="https://www.youtube.com/watch?v=Qx5RdK1N9KM&feature=youtu.be"
              alt="Broadway Media Promotional Video"
              muted={false}
              loop={false}
              controls={true}
              playing={false}
              properties={{
                width: mobile ? "333px" : "626px",
              }}
            />
          </Box>

          <Box
            parse={`mb:30 w:100% d:flex fd:column a:center j:center mb:150 z:3`}
          >
            <Rule parse="!_bxl d:block mb:16" weight={700}>
              Prefer to read all about it?
            </Rule>
            <Rule parse="!_bxl d:block ta:center mb:16">
              Download our catalog for an expansive look at our offerings.
            </Rule>

            <DesignedButton
              theme="tetriary-white"
              icon="book_text"
              label="Download Catalog"
              href="/legal/documents/catalog"
            />
          </Box>
        </div>

        <div className="container">
          <Box parse={`mh:${mobile ? "400" : tablet ? "600" : "450"}`}>
            <TestimonialQuotes testimonials={testimonials} />
          </Box>
        </div>
      </Box>
    </Box>
  );
};
