import { Formatter, useMobile } from "@with-nx/hooks-n-helpers";
import {
  DesignedButton,
  DesignedCardSize,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { ImageGallery } from "@with-nx/simple-ui/organisms";
import { Products, Product } from "libs/hooks-n-helpers/src/types";
import { FC, ReactNode } from "react";
import { FiPhone } from "react-icons/fi";
import { Box } from "simple-effing-primitive-layout";

interface LatestDigitalDropsProps {
  title?: ReactNode;
  drops?: Products;
  onItemClick?: (item: Product) => void;
}

export const LatestDigitalDrops: FC<LatestDigitalDropsProps> = ({
  drops,
  title,
  onItemClick,
}) => {
  const mobile = useMobile();

  if (!drops?.length) {
    return null;
  }

  return (
    <Box parse={`mb:${mobile ? 48 : 96} ta:center`}>
      {title || (
        <>
          <Box>
            <Rule parse="!dm d:block mb:12 ta:center" weight="700" tag="span">
              Discover New Digital Drops
            </Rule>
            <Rule parse="!_p d:block mb:20 ta:center c:?font3">
              With 3,000 animated drops at your fingertips,{" "}
              {mobile ? <br /> : undefined} curate the show collection that
              unlocks your creativity
            </Rule>
          </Box>
          <Box css="d:flex a:flex-start j:center a:center">
            <Box parse="mr:12 mb:12">
              <DesignedButton
                icon={
                  <FiPhone
                    size={20}
                    color="var(--primary)"
                    title="phone icon"
                  />
                }
                label="Schedule Consultation"
                theme="tetriary"
                href="/contact/calendar"
              />
            </Box>
            <Box parse="mr:12 mb:12">
              <DesignedButton
                label="View All"
                theme="primary"
                href="/digital-backdrops"
              />
            </Box>
          </Box>
        </>
      )}

      <Box
        parse="mt:32"
        style={{
          maskImage:
            "linear-gradient(90deg, rgba(255,255,255,0) 0px, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) calc(100% - 20%), rgba(255,255,255,0)",
          WebkitMaskImage:
            "linear-gradient(90deg, rgba(255,255,255,0) 0px, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) calc(100% - 20%), rgba(255,255,255,0)",
        }}
      >
        <ImageGallery
          per={3}
          maxItemWidth={500}
          images={drops?.map((item) => {
            return {
              src: Formatter.image(
                item?.attachments?.find(
                  (i: { name: string }) => i.name === "preview_still"
                )?.blob?.signedUrl
              ),
              pop: false,
              size: "16x9" as DesignedCardSize,
              press: () => {
                if (onItemClick) {
                  onItemClick(item);
                }
              },
            };
          })}
        />
      </Box>
    </Box>
  );
};
