import { DesignedButton } from "@with-nx/simple-ui/atoms";
import { VisibleContainer } from "@with-nx/simple-ui/molecules";
import { useRouter } from "next/router";

export const MobileCommercialTemplate = () => {
  const router = useRouter();

  return (
    <section id="featureds" className="@section">
      <div className="container">
        <h2 className="!hl mb:16 --blue-gradient-text">
          Let&apos;s Put On a Show!
        </h2>
        <p className="!ls mb:32 c:?font3 d:block">
          At Broadway Media, we're revolutionizing the performing arts
          landscape. We innovate, create, and inspire, offering a powerful suite
          of technology tools crafted precisely for the artists of today.
        </p>
        <div className="d:flex a:center fw:wrap mb:24">
          <DesignedButton
            label="Get Started"
            theme="white"
            properties={{ right: 12, bottom: 12 }}
            press={() =>
              router.push(
                `/contact/start?mode=get-started&referrer=${router.asPath}`
              )
            }
          />
          <DesignedButton
            label="Schedule a Call"
            theme="tetriary-white"
            properties={{ right: 12, bottom: 12 }}
            href="/contact/calendar"
          />
        </div>
        <VisibleContainer>
          <div className="@iframe">
            <iframe
              title="Broadway Media Promotional Video"
              src="https://www.youtube.com/embed/Qx5RdK1N9KM"
            />
          </div>
        </VisibleContainer>
      </div>
    </section>
  );
};

export default MobileCommercialTemplate;
