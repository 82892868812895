import { useMobile } from "@with-nx/hooks-n-helpers";
import {
  DesignedButton,
  ReusableCustomerServiceContainer,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { Box } from "simple-effing-primitive-layout";

export const HomeCustomerService = () => {
  const mobile = useMobile();

  return (
    <ReusableCustomerServiceContainer
      showMobile
      title={
        <Rule parse={`!_h2 ${mobile ? "ta:left" : ""}`}>
          Need assistance?
          <br />
          We are waiting in the wings!
        </Rule>
      }
      content={
        <Box>
          <Box parse={`d:flex ${mobile ? "fd:column ta:left" : ""} mb:10`}>
            <Rule parse="!bxl c:var(--grey-40) mr:20">Phone</Rule>
            <Rule parse="!bxl">1-800-277-0343</Rule>
          </Box>

          <Box parse={`d:flex ${mobile ? "fd:column ta:left" : ""} mb:20`}>
            <Rule parse="c:var(--grey-40) mr:20">Hours</Rule>

            <Rule>
              Monday - Friday 8:00am - 11:00pm ET
              <br />
              Saturday and Sunday 12:00pm - 11:00pm ET
            </Rule>
          </Box>
        </Box>
      }
      buttonLabel="Schedule a Meeting"
      buttonTheme="primary"
      actions={
        <Box parse="ml:10">
          <DesignedButton
            label="Join a Workshop"
            theme="tetriary"
            href="/workshops"
          />
        </Box>
      }
      xl={8}
    />
  );
};
