import { UseCMSHomepage } from "@with-nx/hooks-n-helpers";
import { ReusableInstagramCallToAction } from "@with-nx/simple-ui/organisms";

export const HomeInstagramTemplate = ({
  lighthouse = false,
}: {
  lighthouse?: boolean;
  homepage?: UseCMSHomepage;
}) => {
  console.log("⚡️", "HomeInstagramTemplate");

  return (
    <ReusableInstagramCallToAction lighthouse={lighthouse} slanted={true} />
  );
};

export default HomeInstagramTemplate;
