import { Formatter, UseCMSHomepage } from "@with-nx/hooks-n-helpers";
import { DesignedButton } from "@with-nx/simple-ui/atoms";
import { BlueUnderline } from "@with-nx/simple-ui/molecules";
import { GlobalSearchInput } from "@with-nx/simple-ui/organisms";
import moment from "moment";
import Image from "next/image";
import { FiArrowUpRight, FiCalendar } from "react-icons/fi";

export const MobileStoriesTemplate = ({
  homepage,
}: {
  homepage?: UseCMSHomepage;
}) => {
  return (
    <section className="@slanted">
      <div className="@slanted_default" />
      <section id="featureds" className="@section @slanted_content">
        <div className="container">
          <h2 className="!hl mb:16 d:flex a:center j:center ta:center mb:24">
            Let&apos;s put{" "}
            <span className="--blue-gradient-text p:relative ml:6">
              on a show! <BlueUnderline />
            </span>
          </h2>

          <GlobalSearchInput properties={{ bottom: 32 }} />
          <h1 className="!ls mb:32 c:?font3 d:block ta:center">
            Explore the world&apos;s largest selection of officially-sanctioned,
            Broadway-quality, resources tailored to make producing theatre more
            accessible and affordable.
          </h1>

          <h3 className="!hl mb:16">Recent Stories</h3>
        </div>
        <div className="@grid-step-scroller mb:24">
          {homepage?.blogs?.map((blog) => (
            <div className="@grid-step-scroller_item_100%" key={blog._id}>
              <article className="@blog" aria-label={blog?.title}>
                <div className="@blog_header">
                  <div className="@blog_image_container">
                    <a href={`/blog/${blog?.slug}`} rel="noreferrer">
                      <Image
                        src={Formatter.compressed(blog?.coverImage, 512, true)}
                        alt={blog?.title}
                        loading="lazy"
                        className="@blog_image"
                        layout="fill"
                      />
                    </a>
                  </div>
                </div>

                <div className="@blog_body">
                  <span className="!lm d:block mb:16 c:?font1 max-line-3">
                    {blog?.title}
                  </span>
                  <span className="!ps d:block mb:16 c:?font3 max-line-3">
                    {blog?.description}
                  </span>
                  <div className="d:flex a:center">
                    <a
                      className="d:inline-flex a:center"
                      href={`/blog/${blog?.slug}`}
                      rel="noreferrer"
                    >
                      <FiArrowUpRight size={16} color="var(--primary)" />
                      <span className="!ls d:block ml:6 c:?primary">
                        Continue Reading
                      </span>
                    </a>
                    {blog?.date ? (
                      <div className="d:inline-flex a:center ml:6">
                        <FiCalendar size={16} color="#909090" />
                        <span className="!ls d:block ml:6 c:?font3">
                          {moment(blog?.date).isValid()
                            ? moment(blog?.date).format("DD MMMM YYYY")
                            : blog?.date}
                        </span>
                      </div>
                    ) : undefined}
                  </div>
                </div>
              </article>
            </div>
          ))}
        </div>
        <div className="container d:flex a:center j:flex-end">
          <DesignedButton
            size="small"
            theme="tetriary"
            label="See All Stories"
            href="/blog"
          />
        </div>
      </section>
    </section>
  );
};

export default MobileStoriesTemplate;
