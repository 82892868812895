import { UseCMSHomepage } from "@with-nx/hooks-n-helpers";

export const MobileTestimonialsTemplate = ({
  homepage,
}: {
  homepage?: UseCMSHomepage;
}) => {
  return (
    <section id="testimonials" className="@section pb:32vw">
      <div className="container">
        <h5 className="!hl mb:32 ta:center">
          Trusted by our Theatre Community
        </h5>
      </div>
      <div className="@grid-step-scroller">
        {homepage?.testimonials?.reverse()?.map((testimonial) => (
          <div className="@grid-step-scroller_item_100%" key={testimonial._id}>
            <div className="@card:plain">
              <p className="!pm mb:16 c:?font1">
                {testimonial?.description
                  ? testimonial?.description?.length > 256
                    ? testimonial?.description.slice(0, 256) + "..."
                    : testimonial?.description
                  : null}
              </p>
              {testimonial?.title ? (
                <span className="!ls c:?font4">{testimonial?.title}</span>
              ) : undefined}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default MobileTestimonialsTemplate;
