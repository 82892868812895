import { Formatter, UseCMSHomepage, useMobile } from "@with-nx/hooks-n-helpers";
import {
  DesignedButton,
  ReusableSection,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { BlueUnderline } from "@with-nx/simple-ui/molecules";
import {
  GlobalSearchInput,
  GridBlog,
  GridStepScroller,
  ReusableContainerSlantedCallToAction,
} from "@with-nx/simple-ui/organisms";
import { Col, Row } from "antd";
import { useState } from "react";
import { Box } from "simple-effing-primitive-layout";

const Container = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const [focused, _focused] = useState(false);

  return (
    <ReusableContainerSlantedCallToAction
      index="1"
      justify="center"
      gradients="blue"
      lg={24}
      md={24}
      bottom={48}
    >
      <Row align="middle" justify="center">
        <Col xs={24} sm={24} md={18} lg={12}>
          <Box css="d:flex a:center fd:column oy:visible" parse="pt:32 ">
            <Rule tag="h2">
              <Box css="d:inline-flex a:baseline j:center p:relative oy:visible ">
                <Rule
                  parse="!ds d:block"
                  display="block"
                  bottom={7}
                  right={12}
                  tag="span"
                >
                  Let&apos;s put
                </Rule>
                <Box css="p:relative">
                  <Rule
                    parse="!ds d:block"
                    display="block"
                    css="--blue-gradient-text"
                    style={{ marginBottom: 0 }}
                    bottom={0}
                    tag="span"
                  >
                    on a show!
                  </Rule>
                  <BlueUnderline />
                </Box>
              </Box>
            </Rule>

            <Box
              parse="w:100% pa:5 br:5 d:flex a:center c:#171B20 mt:24"
              border={
                focused ? "2px solid var(--primary)" : "1px solid #2D3542"
              }
            >
              <GlobalSearchInput
                focus={() => _focused(true)}
                blur={() => _focused(false)}
                withButton
              />
            </Box>
            <Rule
              parse="!pl d:block ta:center"
              display="block"
              top={20}
              tag="h1"
            >
              Explore the world&apos;s largest selection of
              officially-sanctioned, Broadway-quality, resources tailored to
              make producing theatre more accessible and affordable.
            </Rule>
          </Box>
        </Col>
      </Row>
      {children}
    </ReusableContainerSlantedCallToAction>
  );
};

const Element = ({
  lighthouse = false,
  homepage,
}: {
  lighthouse?: boolean;
  homepage?: UseCMSHomepage;
}) => {
  const mobile = useMobile();

  return (
    <ReusableSection background="transparent">
      <div
        className={"container"}
        style={{
          paddingLeft: mobile ? 0 : undefined,
          paddingRight: mobile ? 0 : undefined,
        }}
      >
        <div className="container">
          <Rule parse="!hl d:block mb:36">Recent Stories</Rule>
        </div>
        <GridStepScroller
          xs={24}
          sm={24}
          md={12}
          lg={8}
          xl={8}
          inside
          items={
            homepage?.blogs.map((blog, b) => {
              if (b > 2) {
                return null;
              }

              return (
                <GridBlog
                  key={blog._id}
                  date={blog.date}
                  description={blog.description}
                  image={
                    lighthouse
                      ? undefined
                      : Formatter.compressed(blog.coverImage, 256, true)
                  }
                  title={blog.title}
                  url={`/blog/${blog.slug}`}
                  transparent={false}
                  properties={{
                    height: "100%",
                  }}
                />
              );
            }) || []
          }
        />
        <Box css="d:flex j:flex-end" parse="mt:24">
          <DesignedButton
            size="small"
            theme="tetriary"
            label="See More Stories"
            href="/blog"
          />
        </Box>
      </div>
    </ReusableSection>
  );
};

export const HomeStoriesTemplate = ({
  lighthouse = false,
  homepage,
}: {
  lighthouse?: boolean;
  homepage?: UseCMSHomepage;
}) => {
  console.log("⚡️", "HomeStoriesTemplate");

  return (
    <Container>
      <Element homepage={homepage} lighthouse={lighthouse} />
    </Container>
  );
};

export default HomeStoriesTemplate;
