import { Rule } from "@with-nx/simple-ui/atoms";
import { GlobalSearchInput } from "@with-nx/simple-ui/organisms";

export const HomeSearch = ({ mobile }: { mobile: boolean }) => {
  return (
    <div className="home-search">
      <h1>Let's Put on a Show!</h1>
      <Rule parse={`!_${mobile ? "bm" : "bxl"}`} tag="h2">
        Explore the largest collection of licensor-approved, Broadway-quality
        production resources tailored to elevate your production value. Whether
        you're a first-time director or a seasoned Theatre-Maker, our theatrical
        resources offer accessible & affordable solutions for all.
      </Rule>
      <div className="search">
        <GlobalSearchInput
          showIcon={false}
          placeholder={mobile ? "Find your show" : "Find your upcoming show"}
          withButton
          buttonIcon="search/bold"
          theme="light"
          size={mobile ? "small" : "large"}
          showLabel={!mobile}
          properties={{
            right: 15,
          }}
        />
      </div>
    </div>
  );
};
