const solutions = [
  {
    title: "School Theatre",
    url: "/solutions/school-theatre",
    image:
      "/assets/solution/500x500/resources-for-school-theatre-provided-by-broadway-media.webp",
  },
  {
    title: "College Theatre",
    url: "/solutions/college-theatre",
    image:
      "/assets/solution/500x500/resources-for-college-theatre-provided-by-broadway-media.webp",
  },
  {
    title: "Community Theatre",
    url: "/solutions/community-theatre",
    image:
      "/assets/solution/500x500/resources-for-community-theatre-provided-by-broadway-media.webp",
  },
  {
    title: "Professional Theatre",
    url: "/solutions/professional-theatre",
    image:
      "/assets/solution/500x500/resources-for-professional-theatre-provided-by-broadway-media.webp",
  },
  {
    title: "Junior Theatre",
    url: "/solutions/junior-theatre",
    image:
      "/assets/solution/500x500/resources-for-junior-theatre-provided-by-broadway-media.webp",
  },
  {
    title: "Choral and Music",
    url: "/solutions/choral-and-music",
    image:
      "/assets/solution/500x500/resources-for-choral-and-music-provided-by-broadway-media.webp",
  },
  {
    title: "Dance and Ballet",
    url: "/solutions/dance",
    image:
      "/assets/solution/500x500/resources-for-dance-and-ballet-provided-by-broadway-media.webp",
  },
];

export const MobileCommunityTemplate = () => {
  return (
    <section id="community" className="@section">
      <div className="container">
        <h3 className="!hl mb:16">Our Community</h3>
        <p className="!ls mb:32 c:?font3 d:block">
          Our commitment goes beyond products - through partnerships, we are
          dedicated to breaking barriers, and ensuring that everyone,
          everywhere, can experience the joy of the performing arts.
        </p>
      </div>
      <div className="@grid-step-scroller">
        {solutions?.map((solution) => (
          <a
            rel="noreferrer"
            aria-label={solution?.title}
            className="@grid-step-scroller_item_100%"
            key={solution?.title}
            href={solution?.url}
          >
            <div
              className="@double-gradient-card @double-gradient-card_1x1"
              title={solution?.title}
              style={{
                backgroundImage: `url(${solution?.image})`,
              }}
            >
              <div className="@double-gradient-card_purple-gradient" />
            </div>
          </a>
        ))}
      </div>
    </section>
  );
};

export default MobileCommunityTemplate;
