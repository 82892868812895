import { VisibleContainer } from "@with-nx/simple-ui/molecules";

import { Footer } from "../../../../../static-brand-components/src";

export const FooterTemplate = ({
  footerNoAngle,
}: {
  footerNoAngle?: boolean;
}) => {
  return (
    <VisibleContainer>
      <Footer footerNoAngle={footerNoAngle} />
    </VisibleContainer>
  );
};
