import { Rule } from "@with-nx/simple-ui/atoms";
import { FC, useEffect, useState } from "react";
import Image, { StaticImageData } from "next/image";

const PulseLink = ({
  x,
  y,
  label,
  description,
  href,
  mobile,
}: {
  x: number;
  y: number;
  label: string;
  description: string;
  href: string;
  mobile: boolean;
  tablet?: boolean;
}) => {
  const [hover, _hover] = useState(false);
  const handleClick = () => {
    if (href.startsWith("#")) {
      const element = document.getElementById(href.replace("#", ""));

      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.location.href = href;
    }
  };

  return (
    <a
      className="pulse-link"
      style={{
        left: `calc(${x}% - 24px)`,
        top: `calc(${y}% - 24px)`,
        zIndex: 50,
      }}
      onMouseEnter={() => _hover(true)}
      onMouseLeave={() => _hover(false)}
    >
      <div
        className="pulse-link-dot"
        onClick={(event) => {
          if (mobile) {
            event.stopPropagation();
          } else {
            handleClick();
          }
        }}
      >
        <div />
      </div>

      <div
        style={{
          opacity: hover ? 1 : 0,
          transition: "opacity 1s",
        }}
        onClick={handleClick}
      >
        <Rule parse={`!_${mobile ? "bm" : "bxl mb:5"} d:block`} weight={700}>
          {label}
        </Rule>
        <Rule parse={`!_${mobile ? "bm" : "bxl"}`} weight={500}>
          {description}
        </Rule>
      </div>
    </a>
  );
};

interface HomeHeroProps {
  hero: any;
  image: StaticImageData;
  mobile: boolean;
  tablet: boolean;
}

export const HomeHero: FC<HomeHeroProps> = ({
  hero,
  image,
  mobile,
  tablet,
}) => {
  const [videoLoaded, _videoLoaded] = useState(false);
  const device = mobile ? "mobile" : tablet ? "tablet" : "desktop";

  useEffect(() => {
    const videoElement = document.createElement("video");

    videoElement.src = hero.video;
    videoElement.muted = true;

    const handleLoadedData = () => {
      _videoLoaded(true);
    };

    videoElement.addEventListener("loadeddata", handleLoadedData);

    return () => {
      videoElement.removeEventListener("loadeddata", handleLoadedData);
    };
  }, [hero.video]);

  return (
    <div>
      <div
        style={{
          marginTop: "-100px",
          position: "relative",
          width: "100%",
          minHeight: mobile ? "631px" : "1106px",
        }}
      >
        {hero.links.map((link: any) => (
          <PulseLink
            key={link.label}
            x={link.positions[device].x}
            y={link.positions[device].y}
            label={link.label}
            description={link.description}
            href={link.href}
            mobile={mobile}
            tablet={tablet}
          />
        ))}

        {!videoLoaded && (
          <Image
            src={image}
            layout="fill"
            objectFit="cover"
            priority
            alt="Broadway media picture"
            objectPosition="center"
            placeholder="blur"
          />
        )}

        <video
          autoPlay
          muted
          playsInline
          loop
          preload="none"
          src={hero.video}
          style={{
            display: videoLoaded ? "block" : "none",
            objectFit: "cover",
            position: "absolute",
            objectPosition: "center",
            width: "100%",
            height: "100%",
          }}
        />

        <div
          style={{
            background: `linear-gradient(0deg, rgba(57, 58, 139, 0.00) 20.24%, ${hero.gradients.top} 58.87%)`,
            width: "100%",
            position: "absolute",
            height: "50%",
            top: "-10%",
          }}
        />

        <div
          style={{
            background: `linear-gradient(0deg, ${hero.gradients.bottom} 20.24%, rgba(57, 58, 139, 0.00) 58.87%)`,
            width: "100%",
            position: "absolute",
            height: "40%",
            bottom: "0",
          }}
        />
      </div>
    </div>
  );
};
