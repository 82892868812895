import { DesignedButton } from "@with-nx/simple-ui/atoms";

export const MobileInstagramTemplate = () => {
  return (
    <section className="@slanted" style={{ marginTop: 48 }}>
      <div className="@slanted_instagram" />
      <section className="container @slanted_content">
        <h6 className="!hl mb:16"> See Our Products in Action</h6>
        <p className="!ls mb:16 c:?font1 d:block">
          Explore our Instagram to see productions featuring Broadway Media
          products. Join our vibrant community and witness our technology tools
          help make stages come to life!
        </p>
        <DesignedButton
          label="Go to our Instagram"
          properties={{
            element: "a",
            native: {
              href: "https://www.instagram.com/broadwaymedia/",
              target: "_blank",
            },
            bottom: 32,
          }}
          theme="white"
          size="large"
          type={{
            style: {
              whiteSpace: "nowrap",
              background:
                "linear-gradient(269.2deg, #771ADE -35.15%, #8F38C6 4.63%, #CF4E9A 49.99%, #E37869 78.46%, #E78240 103.87%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              textFillColor: "transparent",
            },
          }}
        />
        <a
          href="https://www.instagram.com/broadwaymedia/"
          target="_blank"
          rel="nofollow"
          aria-label="Go to our Instagram"
        >
          <img
            loading="lazy"
            alt="Broadway Media's Instagram"
            src="/assets/section/instagram.webp"
            className="full-width-image"
          />
        </a>
      </section>
    </section>
  );
};

export default MobileInstagramTemplate;
