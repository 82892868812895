import { Formatter, UseCMSHomepage } from "@with-nx/hooks-n-helpers";

export const MobilePartnersTemplate = ({
  homepage,
}: {
  homepage?: UseCMSHomepage;
}) => {
  return (
    <section id="partners" className="@section">
      <div className="container">
        <h6 className="!hl mb:32">Our Partners</h6>
      </div>
      <div className="@grid-step-scroller">
        {homepage?.partners?.reverse()?.map((partner) => (
          <div className="@grid-step-scroller_item_256" key={partner._id}>
            <a
              rel="noreferrer"
              className="@card:16x9 d:block @card_foreground"
              aria-label={partner?.name}
              href={`/partners/${partner?.slug}`}
            >
              <img
                className="@card:16x9_image"
                alt={partner?.name}
                src={Formatter.compressed(partner?.image, 256)}
                loading="lazy"
              />
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default MobilePartnersTemplate;
