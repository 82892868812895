import { Formatter, UseCMSHomepage } from "@with-nx/hooks-n-helpers";
import { DesignedButton } from "@with-nx/simple-ui/atoms";

export const MobileFeaturedShowsTemplate = ({
  homepage,
}: {
  homepage?: UseCMSHomepage;
}) => {
  return (
    <section id="featureds" className="@section">
      <div className="container">
        <h3 className="!hl mb:32">Explore Featured Shows</h3>
      </div>
      <div className="@grid-step-scroller mb:24">
        {homepage?.featureds?.map((featured) => (
          <a
            rel="noreferrer"
            aria-label={featured?.name}
            className="@grid-step-scroller_item_100%"
            key={featured._id}
            href={featured?.url}
          >
            <div
              className="@double-gradient-card"
              title={featured?.name}
              style={{
                backgroundImage: `linear-gradient(145deg, ${
                  featured?.logoColor ? featured?.logoColor : "#000"
                }, var(--foreground))`,
              }}
            >
              <img
                src={Formatter.compressed(featured?.image, 256)}
                alt={featured?.name}
                className="@double-gradient-card_image"
                loading="lazy"
              />
            </div>
          </a>
        ))}
      </div>
      <div className="container d:flex a:center j:flex-end">
        <DesignedButton
          size="small"
          theme="tetriary"
          label="Explore All Shows"
          href="/search"
        />
      </div>
    </section>
  );
};

export default MobileFeaturedShowsTemplate;
