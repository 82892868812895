import { Formatter, UseCMSHomepage } from "@with-nx/hooks-n-helpers";
import { DesignedButton } from "@with-nx/simple-ui/atoms";
import useMediaQuery from "libs/hooks-n-helpers/src/useMediaQuery";
import Image from "next/image";
import { useState } from "react";

export const MobileSlidersTemplate = ({
  homepage,
  showSliderControls,
}: {
  homepage?: UseCMSHomepage;
  showSliderControls?: boolean;
}) => {
  const [current, _current] = useState(0);
  const slide = homepage?.slides?.[current];

  return (
    <section className="@slide">
      <Image
        src={Formatter.compressed(slide?.coverImage, 512, true)}
        layout="fill"
        objectFit="cover"
        priority
      />

      <div className="@slide_content">
        {slide ? (
          <div className="container">
            {slide?.logoImage ? (
              <Image
                src={Formatter.compressed(slide?.logoImage, 512, true)}
                width={256}
                height={140}
                objectFit="contain"
                objectPosition="center left"
                alt={slide?.title}
                className="@slide_content_logo"
                priority
              />
            ) : undefined}
            <h2 className="!_t d:block mb:16">{slide.title}</h2>
            <p className="!_p d:block mb:32">{slide.description}</p>
            <DesignedButton
              label={slide.buttonText}
              theme="white"
              size="large"
              href={slide.buttonURL}
            />
          </div>
        ) : undefined}
      </div>
    </section>
  );
};

export default MobileSlidersTemplate;
