import React from "react";

/* eslint-disable-next-line */
export interface LayoutProps {
  header: React.ReactNode;
  children: React.ReactNode;
  footer: React.ReactNode;
  className?: string;
  hideNavBar?: boolean;
  hideFooter?: boolean;
  showCurtainWarmers?: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({
  header,
  children,
  footer,
  className,
  hideNavBar: hideNavBarProp,
  hideFooter: hideFooterProp,
  showCurtainWarmers: showCurtainWarmersProp,
}) => {
  const curtainWarmers = showCurtainWarmersProp || false;

  return (
    <div className={className}>
      {curtainWarmers ? showCurtainWarmersProp : ""}
      {hideNavBarProp ? "" : header}
      {children}
      {hideFooterProp ? "" : footer}
    </div>
  );
};

export default Layout;
